import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/'
import SEO from '../components/seo/'
import Date from '../components/date'

const IndexPage = props => (
  <Layout>
    <div className="page">
      <h2 className="dates-head">DATES</h2>
      <br />
      <SEO title="LACK" keywords={[`records`, `techno`, `label`]} />
      {props.data.dates.edges.map(date => (
        <Date slug={date.node.slugs[0]} data={date.node.data} />
      ))}
    </div>
  </Layout>
)

export default IndexPage

export const IndexQuery = graphql`
  query Dates {
    dates: allPrismicDate(
      limit: 10
      sort: { fields: [data___eventdate], order: DESC }
    ) {
      edges {
        node {
          slugs
          data {
            title {
              text
            }
            eventdate
          }
        }
      }
    }
  }
`
