import React from 'react'
import { Link } from 'gatsby'
import '../date/style.scss'
import moment from 'moment'

const Date = props => (
  <Link to={`/dates/${props.slug}`} className="c-date">
    <h2>
      {moment(props.data.eventdate).format('DD MMM YYYY')} -{' '}
      {props.data.title.text}
    </h2>
  </Link>
)

export default Date
